import home from './home.js';
import login from './login.js';
const notFound = [{
    path: "/404",
    name: '404',
    component: () => import("../../views/404.vue")
  },
  {
    path: '/:pathMatch(.*)*', // vue2可以使用 * ，vue3使用/:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
    redirect: {
      name: "404"
    }
  }
]
const someThing = [
  {
    path: "/factory/screen",
    name: "factoryScreen",
    meta:{
      title: '大屏',
      scroll: true,
      permission: "waste:summary:dashboard:dataPlatform"
    },
    component: () => import("../../views/factory/screen.vue")
  },
  {
    path: "/factory/screen2",
    name: "factoryScreen2",
    meta:{
      title: '大屏',
      scroll: true,
      permission: "waste:summary:dashboard:operateMonitor"
    },
    component: () => import("../../views/factory/screen2.vue")
  },
  {
    path: "/hazardous/screen",
    name: "hazardousScreen",
    meta:{
      title: '大屏',
      scroll: true,
      permission: "hw:summary:dashboard:control"
    },
    component: () => import("../../views/hazardous/screen.vue")
  }
]
export default [...login, ...home, ...notFound, ...someThing]
