export default[
  {
    path: "/factory",
    name: "factory",
    meta:{
      title: '首页',
      scroll: true,
      permission: ''
    },
    component: () => import("../../views/layout.vue"),
    redirect: '/factory/index',
    children: [
      {
        path: "/factory/index",
        name: "factoryHome",
        meta:{
          title: '首页',
          scroll: true,
          permission: "home:SWIndex"
        },
        component: () => import("../../views/factory/home.vue")
      },
      {
        path: '/factory/monitor',
        name: 'factoryMonitor',
        meta:{
          title: '监控列表',
          scroll: true,
          permission: ''
        },
        component: () => import("../../views/factory/monitor.vue"),
      },
      {
        path: "/factory/waste",
        name: "factoryWaste",
        meta:{
          title: '固废管理',
          scroll: true,
          permission: 'waste'
        },
        component: () => import("../../views/factory/waste/index.vue"),
        redirect: '/factory/waste/feeding',
        children: [
          {
            path: '/factory/waste/feeding',
            name: 'factoryWasteFeeding',
            meta:{
              title: '投料记录',
              scroll: true,
              permission: 'waste:deliveryDetail'
            },
            component: () => import("../../views/factory/waste/feeding.vue"),
          },
          {
            path: '/factory/waste/feedingDetail',
            name: 'factoryWasteFeedingDetail',
            meta:{
              title: '投料详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/feedingDetail.vue"),
          },
          {
            path: '/factory/waste/storage',
            name: 'factoryWasteStorage',
            meta:{
              title: '暂存单',
              scroll: true,
              permission: 'waste:storageDetail'
            },
            component: () => import("../../views/factory/waste/storage.vue"),
          },
          {
            path: '/factory/waste/storageDetail',
            name: 'factoryWasteStorageDetail',
            meta:{
              title: '废料详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/storageDetail.vue"),
          },
          {
            path: '/factory/waste/storageSuccess',
            name: 'factoryWasteStorageSuccess',
            meta:{
              title: '操作成功',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/storageSuccess.vue"),
          },
          {
            path: '/factory/waste/orderIn',
            name: 'factoryWasteOrderIn',
            meta:{
              title: '入库单',
              scroll: true,
              permission: 'waste:orderIn'
            },
            component: () => import("../../views/factory/waste/orderIn.vue"),
          },
          {
            path: '/factory/waste/orderInDetail',
            name: 'factoryWasteOrderInDetail',
            meta:{
              title: '入库详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/orderInDetail.vue"),
          },
          {
            path: '/factory/waste/orderOut',
            name: 'factoryWasteOrderOut',
            meta:{
              title: '出库单',
              scroll: true,
              permission: 'waste:orderOut'
            },
            component: () => import("../../views/factory/waste/orderOut.vue"),
          },
          {
            path: '/factory/waste/orderOutDetail',
            name: 'factoryWasteOrderOutDetail',
            meta:{
              title: '出库详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/orderOutDetail.vue"),
          },
          // {
          //   path: '/factory/waste/workOrder',
          //   name: 'factoryWasteWorkOrder',
          //   meta:{
          //     title: '工单',
          //     scroll: true,
          //     permission: 'waste:mapNumMaterial'
          //   },
          //   component: () => import("../../views/factory/waste/workOrder.vue"),
          // },
          {
            path: '/factory/waste/workOrderDetail',
            name: 'factoryWasteWorkOrderDetail',
            meta:{
              title: '工单详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/workOrderDetail.vue"),
          },
          {
            path: '/factory/waste/bration',
            name: 'factoryWasteBration',
            meta:{
              title: '仓库管理',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/bration.vue"),
          },
          {
            path: '/factory/waste/brationDetail',
            name: 'factoryWasteBrationDetail',
            meta:{
              title: '仓库管理详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/brationDetail.vue"),
          },
          {
            path: '/factory/waste/deliveryCode',
            name: 'factoryWasteDeliveryCode',
            meta:{
              title: '投料码',
              scroll: true,
              permission: 'waste:deliveryCode'
            },
            component: () => import("../../views/factory/waste/deliveryCode.vue"),
          },
          {
            path: '/factory/waste/destroyProve',
            name: 'factoryWasteDestroyProve',
            meta:{
              title: '销毁证明',
              scroll: true,
              permission: 'waste:destroyProve'
            },
            component: () => import("../../views/factory/waste/destroyProve.vue"),
          },
          {
            path: '/factory/waste/destroyProveDetail',
            name: 'factoryWasteDestroyProveDetail',
            meta:{
              title: '销毁证明详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/destroyProveDetail.vue"),
          },
          {
            path: '/factory/waste/project',
            name: 'factoryWasteProject',
            meta:{
              title: '产废项目',
              scroll: true,
              permission: 'waste:deliveryExtend'
            },
            component: () => import("../../views/factory/waste/project.vue"),
          },
          {
            path: '/factory/waste/projectDetail',
            name: 'factoryWasteProjectDetail',
            meta:{
              title: '产废项目详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/waste/projectDetail.vue"),
          }
        ]
      },
      {
        path: "/factory/settle",
        name: "factorySettle",
        meta:{
          title: '结账管理',
          scroll: true,
          permission: 'settle'
        },
        component: () => import("../../views/factory/settle/index.vue"),
        redirect: '/factory/settle/wait',
        children: [
          {
            path: "/factory/settle/wait",
            name: "factorySettleWait",
            meta:{
              title: '待结账',
              scroll: true,
              permission: 'settle:wait'
            },
            component: () => import("../../views/factory/settle/wait.vue")
          },
          {
            path: "/factory/settle/complete",
            name: "factorySettleComplete",
            meta:{
              title: '结账单',
              scroll: true,
              permission: 'settle:complete'
            },
            component: () => import("../../views/factory/settle/complete.vue")
          },
          {
            path: "/factory/settle/completeDetail",
            name: "factorySettleCompleteDetail",
            meta:{
              title: '结账单详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/settle/completeDetail.vue")
          },
          {
            path: '/factory/settle/settleSuccess',
            name: 'factorySettleSettleSuccess',
            meta:{
              title: '操作成功',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/settle/settleSuccess.vue"),
          },
          {
            path: "/factory/settle/collectDetail",
            name: "factorySettleCollectDetail",
            meta:{
              title: '修正详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/settle/collectDetail.vue")
          },
        ]
      },
      {
        path: "/factory/analysis",
        name: "factoryAnalysis",
        meta:{
          title: '数据分析',
          scroll: true,
          permission: 'waste:summary'
        },
        component: () => import("../../views/factory/analysis/index.vue"),
        redirect: '/factory/analysis/output',
        children: [
          {
            path: "/factory/analysis/dataAnalysis",
            name: "factoryAnalysisDataAnalysis",
            meta:{
              title: '产废分析',
              scroll: true,
              permission: 'waste:summary:produce'
            },
            component: () => import("../../views/factory/analysis/dataAnalysis.vue")
          },
          {
            path: "/factory/analysis/output",
            name: "factoryAnalysisOutput",
            meta:{
              title: '累计产量',
              scroll: true,
              permission: 'waste:summary:output'
            },
            component: () => import("../../views/factory/analysis/output.vue")
          },
          {
            path: "/factory/analysis/stockStat",
            name: "factoryAnalysisStockStat",
            meta:{
              title: '库存统计',
              scroll: true,
              permission: 'waste:summary:stockStat'
            },
            component: () => import("../../views/factory/analysis/stockStat.vue")
          },
          {
            path: "/factory/analysis/dashboard",
            name: "factoryAnalysisDashboard",
            meta:{
              title: '数据大厅',
              scroll: true,
              permission: 'waste:summary:dashboard'
            },
            component: () => import("../../views/factory/analysis/dashboard.vue")
          }
        ]
      },
      {
        path: "/factory/ledger",
        name: "factoryLedger",
        meta:{
          title: '环保台账',
          scroll: true,
          permission: 'ledger'
        },
        component: () => import("../../views/factory/ledger/index.vue"),
        redirect: '/factory/ledger/produceList',
        children: [
          {
            path: "/factory/ledger/produceList",
            name: "factoryLedgerProduceList",
            meta:{
              title: '废物产生清单',
              scroll: true,
              permission: 'ledger:produceList'
            },
            component: () => import("../../views/factory/ledger/produceList.vue")
          },
          {
            path: "/factory/ledger/produceList-value",
            name: "factoryLedgerProduceListValue",
            meta:{
              title: '废物产生清单详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/ledger/produceList-value.vue")
          },
          {
            path: "/factory/ledger/flowDirection",
            name: "/factoryLedgerFlowDirection",
            meta:{
              title: '废物流向表',
              scroll: true,
              permission: 'ledger:flowDirection'
            },
            component: () => import("../../views/factory/ledger/flowDirection.vue")
          },
          {
            path: "/factory/ledger/flowDirection-value",
            name: "factoryLedgerFlowDirectionValue",
            meta:{
              title: '废物流向表详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/ledger/flowDirection-value.vue")
          },
          {
            path: "/factory/ledger/factoryLink",
            name: "factoryLedgerFactoryLink",
            meta:{
              title: '废物出厂环节表',
              scroll: true,
              permission: 'ledger:factoryLink'
            },
            component: () => import("../../views/factory/ledger/factoryLink.vue")
          },
          {
            path: "/factory/ledger/factoryLink-value",
            name: "factoryLedgerFactoryLinkValue",
            meta:{
              title: '废物出厂环节表详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/ledger/factoryLink-value.vue")
          },
          {
            path: "/factory/ledger/produceLink",
            name: "factoryLedgerProduceLink",
            meta:{
              title: '废物产生环节表',
              scroll: true,
              permission: 'ledger:produceLink'
            },
            component: () => import("../../views/factory/ledger/produceLink.vue")
          },
          {
            path: "/factory/ledger/produceLink-value",
            name: "factoryLedgerProduceLinkValue",
            meta:{
              title: '废物产生环节表详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/factory/ledger/produceLink-value.vue")
          },
        ]
      },
      {
        path: "/factory/setting",
        name: "factorySetting",
        meta:{
          title: '系统设置',
          scroll: true,
          permission: 'setting'
        },
        component: () => import("../../views/factory/setting/index.vue"),
        redirect: '/factory/setting/device',
        children: [
          {
            path: "/factory/setting/user",
            name: "factorySettingUser",
            meta:{
              title: '用户管理',
              scroll: true,
              permission: 'setting:sysUser'
            },
            component: () => import("../../views/factory/setting/user.vue")
          },
          {
            path: "/factory/setting/psa",
            name: "factorySettingPsa",
            meta:{
              title: '物料号关联',
              scroll: true,
              permission: "setting:psaMap"
            },
            component: () => import("../../views/factory/setting/psa.vue")
          },
          {
            path: "/factory/setting/linked",
            name: "factorySettingLinked",
            meta:{
              title: '投料卡关联',
              scroll: true,
              permission: "setting:psaCardMap"
            },
            component: () => import("../../views/factory/setting/linked.vue")
          },
          {
            path: "/factory/setting/material",
            name: "factorySettingMaterial",
            meta:{
              title: '材料关联',
              scroll: true,
              permission: "setting:mapMaterialWaste"
            },
            component: () => import("../../views/factory/setting/material.vue")
          },
        ]
      },
    ]
  }
]